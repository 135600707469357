<!-- 空状态 -->
<template>
    <div class="empty-container flex-column align-c">
        <img :src="imgUrl">
        <p class="font-16 mt-20">{{title}}</p>
    </div>
</template>

<script>
import emptyDefault from '@/assets/images/empty_default.png'
import emptyCart from '@/assets/images/empty_cart.png'
import emptySearch from '@/assets/images/empty_search.png'

export default {
    name: 'Empty',
    components: {},

    props: {
        type: '',
        title: ''
    },

    data() {
        return {
            imgUrl: ''
        };
    },

    created() {
        if(this.type && this.type == 'cart') {
            this.imgUrl = emptyCart
        } else if(this.type && this.type == 'search'){
            this.imgUrl = emptySearch
        } else {
            this.imgUrl = emptyDefault
        }
    }
}
</script>
<style lang='scss' scoped>
    .empty-container{
        width: 100%;
        padding: 30px 0;
        text-align: center;
    }
</style>