<!-- 标题 -->
<template>
    <div>
        <h3 class="title">{{title}}</h3>
    </div>
</template>

<script>
export default {
    name: 'Title',
    components: {},
    props: {
        title: ''
    },

    data() {
        return {
            
        };
    },

    created() {
        
    },

    methods: {

    }
}
</script>
<style lang='scss' scoped>
    .title{
        border-left: 5px solid var(--theme-color);
        padding-left: 8px;
        font-size: 22px;
        font-weight: normal;
        margin: 30px 0;
    }
</style>